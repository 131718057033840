<template>
	<v-flex pa-0 ma-0 sm4 xs12>
		<cards-menu 
			ref="cards_menu"
			@onRemove="updateCards"
		></cards-menu>
		<available-cards 
			ref="available_cards_menu"
			@onAdd="updateCards"
		></available-cards>
	</v-flex>	
</template>
<script>
import CardsMenu from './CardsMenu.vue';
import AvailableCards from './AvailableCards.vue';
export default {
	name: "CardsAndAvailableCards",
	components: {CardsMenu, AvailableCards},
	methods: {
		getAvailableCards() {
			this.$refs.available_cards_menu.getAvailableCards();
		},
		getCards() {
			this.$refs.cards_menu.getCards();
		},
		updateCards() {
			this.getAvailableCards();
			this.getCards();
		}, 
	}
}
</script>
