<template>
	<div fluid sm4 xs12>
		<v-card class="mb-3">
			<v-card-title>
				<v-text-field
					v-model="search"
					append-icon="search"
					:label='t("Search")'
					@change="getCards"
					single-line
					hide-details
				>
				</v-text-field>
			</v-card-title>
		</v-card>
		<v-card class="my-3">
			<v-subheader>{{t('Cards')}}</v-subheader>
			<v-data-table
				:headers="headers"
				:items="cards"
				:total-items="cards_page_size"
				:pagination.sync="cards_pagination"
				class="elevation-1"
				:loading="loadingCards"
				:search="search"
				hide-actions
			>
				<template slot="items" slot-scope="props">
					<td class="text-xs-left no-wrap">{{ props.item.label }}</td>
					<td class="text-xs-left no-wrap">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" :to="`/cards/${props.item.pk}/details/`" color="info"
									   ripple
									   icon small dark>
									<v-icon small>info</v-icon>
								</v-btn>
							</template>
							<span>{{t('Detail')}}</span>
						</v-tooltip>
						<v-tooltip bottom v-if="props.item.status !== 'Active'">
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" color="error" @click="removeCard(props.item.pk)" ripple
									   icon
									   small dark>
									<v-icon small>close</v-icon>
								</v-btn>
							</template>
							<span>{{t('Remove')}}</span>
						</v-tooltip>
					</td>
				</template>
				<template slot="no-data">
					<v-subheader>{{t('No data available')}}</v-subheader>
				</template>
			</v-data-table>
			<div style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
				<v-pagination
					v-model="cards_pagination.current"
					:length="cards_pagination.total"
					:total-visible="6"
				></v-pagination>
				<v-select style="margin-left: 10px; max-width: 50px" :items="[5, 10, 25, 50]" v-model="cards_page_size">
				</v-select>
			</div>
		</v-card>
	</div>
</template>
<script>
	import axios from 'axios';

	export default {
		name: 'CardsMenu',
		data() {
			return {
				search: '',
				cards: [],
				cards_pagination: {
					current: 1,
					total: 20
				},
				cards_page_size: 5,
				loadingCards: true,
				headers: [
					{
						text: this.$translate.locale['Label'],
						align: 'left',
						value: 'label',
					},
					{
						text: this.$translate.locale['Action'],
						value: 'action',
						sortable: false
					},
				],
				}
		},
		methods: {
			getCards() {
				this.loadingCards = true;
				let url = this.cards_pagination.current === 1
					? `/api/resellers/cards/${this.$route.params.id}/` + '?page_size=' + this.cards_page_size
					: `/api/resellers/cards/${this.$route.params.id}/?page=` + this.cards_pagination.current + '&page_size=' + this.cards_page_size;
				
				if (this.search) url += '&id=' + this.search;

				axios.get(url)
					.then((response) => {
						this.cards = response.data.results;
						this.cards_pagination.total = response.data.total_pages;
						this.loadingCards = false;
					}).catch((error) => {
						this.text = '?';
						this.snackbar = true;
						this.loadingCards = false;
				});
			},
            removeCard(pk) {
                axios.put(`/api/resellers/pickup/${this.$route.params.id}/`, {card: pk})
                    .then((response) => {
                        if (response.status === 200) {
							this.$emit('onRemove');
                        }
                    }).catch((error) => {
                    this.text = "Connection error";
                    this.snackbar = true;
                });
            },
		},
		mounted() {
			this.getCards();
		},
		watch: {
            'cards_pagination.current': {
                handler(newValue) {
                    this.getCards();
                }
            },
            'cards_page_size': {
                handler(newValue) {
                    this.getCards()
                }
            }
		}
	}
</script>
