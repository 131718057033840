<template>
	<div 
		fluid
		class="logs"
		:class="{'fullscreen': fullscreen, 'fadeOut': fadeOut}"
	>
		<v-card class="mt-3" v-if="$store.getters.isAdmin || $store.getters.isOwner">
			<v-card-title>
				<v-text-field
					v-model="searchLogs"
					append-icon="search"
					:label='t("Search")'
					single-line
					hide-details
				>
				</v-text-field>
			</v-card-title>
		</v-card>
		<v-card class="my-3" v-if="$store.getters.isAdmin || $store.getters.isOwner">
			<v-flex d-flex>
				<v-subheader>{{t('Logs')}}</v-subheader>
				<div
					class="justify-end align-center"
					style="display: flex;"
				>
					<v-btn
						icon ripple
						@click="toggleFullscreen"
					>
						<v-icon
							color="grey"
							size="35"
							style="cursor: pointer"
						>
							<template
								v-if="!fullscreen"
							>
								fullscreen
							</template>
							<template
								v-else
							>
								fullscreen_exit
							</template>
						</v-icon>
					</v-btn>
				</div>
			</v-flex>
			<v-data-table
				:pagination.sync="logsOptions"
				:headers="headersLogs"
				:items="logs"
				items-per-page="5"
				class="elevation-1"
				:search="searchLogs"
				:rows-per-page-text="t('Rows per page')"
				:rows-per-page-items='[10,25, 50, {text: $translate.locale["All"], value: -1}]'
			>
				<template slot="items" slot-scope="props">
					<td class="text-xs-left no-wrap">
						{{ props.item.date }}
					</td>
					<td class="text-xs-left no-wrap">{{ props.item.log }}</td>
				</template>
				<template slot="pageText" slot-scope="item">
					{{t('Elements')}} {{item.pageStart}} - {{item.pageStop}}, {{t('total')}}: {{item.itemsLength}}
				</template>
				<template slot="no-data">
					<v-subheader>{{t('No data available')}}</v-subheader>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>
<script>
export default {
	name: 'Logs',
	props: ['logs'],
	data() {
		return {
			fullscreen: false,
			fadeOut: false,
			searchLogs: '',
			headersLogs: [
				{
					text: this.$translate.locale['Date'], 
					value: 'date_timestamp'
				},
				{
					text: this.$translate.locale['Header'],
					align: 'left',
					value: 'log',
				},
			],
			logsOptions: {
				sortBy: 'date_timestamp',
				descending: true,
			},
		}
	},
	methods: {
		toggleFullscreen() {
			if (this.fullscreen) {
				this.fadeOut = true;
				setTimeout(() => {
					this.fullscreen = !this.fullscreen;
					this.fadeOut = !this.fadeOut;
				}, 300);
			} else {
				this.fullscreen = !this.fullscreen;
			}
			window.scrollTo(0, 0);
		}
	}
}
</script>
<style>
.logs {
	transition: .3s;
}
.fadeOut {
	transform: translateY(-10px) !important;
	padding: 0 !important;
	opacity: 0 !important;
}
.fullscreen {
	animation: .3s linear fadeIn;
	background: rgba(0, 0, 0, .3);
	position: absolute;
	padding: 0 10px;
	width: 100%;
	height: 100%;
	top: -10px;
	left: 0;
	transform: translateY(10px);
	z-index: 1;
}
@keyframes fadeIn {
	0% {
		opacity: 0%;
	}
	100% {
		opacity: 100%;
	}
}
</style>
