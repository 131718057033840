<template>
	<div fluid sm4 xs12>
		<v-card class="mb-3">
			<v-card-title>
				<v-text-field
					v-model="asearch"
					append-icon="search"
					:label='t("Search")'
					single-line
					hide-details
					@change="getAvailableCards"
				>
				</v-text-field>
			</v-card-title>
		</v-card>
		<v-card class="my-3" v-if="$store.getters.isAdmin || $store.getters.isOwner">
			<v-subheader>{{t('Available cards')}}</v-subheader>
			<v-data-table
				:headers="headers"
				:items="aviableCards"
				:total-items="available_page_size"
				:pagination.sync="available_pagination"
				hide-actions
				class="elevation-1"
				:loading="loadingAviableCards"
				:search="asearch"
			>
				<template slot="items" slot-scope="props">
					<td class="text-xs-left no-wrap">{{ props.item.label }}</td>
					<td class="text-xs-left no-wrap">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" :to="`/cards/${props.item.pk}/details/`" color="info"
									   ripple
									   icon small dark>
									<v-icon small>info</v-icon>
								</v-btn>
							</template>
							<span>{{t('Detail')}}</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" @click="addCard(props.item.pk)" color="success" ripple icon
									   small dark>
									<v-icon small>add</v-icon>
								</v-btn>
							</template>
							<span>{{t('Add')}}</span>
						</v-tooltip>
					</td>
				</template>
				<template slot="no-data">
					<v-subheader>{{t('No data available')}}</v-subheader>
				</template>
			</v-data-table>
			<div style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
				<v-pagination
					v-model="available_pagination.current"
					:length="available_pagination.total"
					:total-visible="6"
				></v-pagination>
				<v-select style="margin-left: 10px; max-width: 50px" :items="[5, 10, 25, 50]" v-model="available_page_size">
				</v-select>
			</div>
		</v-card>
	</div>
</template>
<script>
import axios from 'axios';

export default {
	name: 'AvailableCardsMenu',
	data() {
		return {
			asearch: '',
			headers: [
				{
					text: this.$translate.locale['Label'],
					align: 'left',
					value: 'label',
				},
				{
					text: this.$translate.locale['Action'],
					value: 'action',
					sortable: false,
				},
			],
			available_page_size: 5,
			available_pagination: {
				current: 1,
				total: 20,
			},
			loadingAviableCards: true,
			aviableCards: [],
		}
	},
	methods: {
		addCard(pk) {
			axios.put(`/api/resellers/tie/${this.$route.params.id}/`, {card: pk})
				.then((response) => {
					if (response.status === 200) {
						this.$emit('onAdd');
					}
				}).catch((error) => {
				this.text = "Connection error";
				this.snackbar = true;
			});
		},
		getAvailableCards() {
			this.loadingAviableCards = true;
			let url = this.available_pagination.current === 1
				? `/api/availableCards/` + '?page_size=' + this.available_page_size
				: '/api/availableCards/?page=' + this.available_pagination.current + '&page_size=' + this.available_page_size;
			if (this.asearch) url += '&id=' + this.asearch;
			axios.get(url)
				.then((response) => {
					this.aviableCards = response.data.results;
					this.available_pagination.total = response.data.total_pages;
					if (this.aviableCards.length > 0) {
						this.min = this.aviableCards[0].pk;
						this.range = [this.min, this.min + 10];
					}
				}).catch((error) => {
					this.text = 'Something error';
					this.snackbar = true;
			}).finally(() => {
				this.loadingAviableCards = false;
			})
		}
	},
	mounted() {
		this.getAvailableCards();
	},
	watch: {
		'available_pagination.current': {
			handler(newValue) {
				this.getAvailableCards();
			}
		},
		'available_page_size': {
			handler(val) {
				this.getAvailableCards();
			}
		},
	}
}
</script>
