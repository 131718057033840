<template>
  <v-content color="primary">
    <v-container fluid id="main-container" grid-list-md fill-height>
      <v-dialog v-model="addManyCards" max-width="290" v-if="this.$store.getters.isAdmin || this.$store.getters.isOwner">
        <v-form ref="formManyCards">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="px-4">
                  <v-range-slider
                      v-model="range"
                      :max="max"
                      :min="min"
                      hide-details
                      class="align-center"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                          v-model="range[0]"
                          :rules="[rules.counterRange, rules.numberRange]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                      >
                      </v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                          v-model="range[1]"
                          :rules="[rules.counterRange, rules.numberRange]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                      >
                      </v-text-field>
                    </template>
                  </v-range-slider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer>
              </v-spacer>
              <v-btn outline small color="error" @click.stop="addManyCards = false">{{t('Close')}}</v-btn>
              <v-btn small text color="primary" @click.stop="applyManyCards">{{t('Add')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-snackbar
				v-model="snackbar"
				:timeout="timeout"
				top
      >
        {{ text }}
        <v-btn
					flat
					@click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <v-layout
          justify-center
          wrap
      >
        <v-flex sm8 xs12>
          <v-card
						class="mx-auto"
						:loading="loading"
          >
            <v-list two-line subheader dense>
              <v-subheader>{{t('Details')}}
                <v-dialog v-model="deleteDialog" max-width="290">
                  <template v-if="canDelete" v-slot:activator="{ on }">
                    <v-btn color="error" v-on="on" small icon ripple class="ml-auto mr-0">
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">{{t('Are you sure you want to delete?')}}</v-card-title>
											<v-card-text>{{t('This action cannot be undone')}}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn small color="error" @click="deleteDialog = false">{{t('Close')}}</v-btn>
                      <v-btn outline small color="error" @click="deleteSubscriber">{{t('Delete')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-subheader>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">person</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Name')}}</v-list-tile-title>
                  <p class="mb-0">{{details.first_name}} {{details.last_name}}
                  </p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">person</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('surname')}}</v-list-tile-title>
                  <p class="mb-0">{{details.username}}
                  </p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
                  v-if="$store.getters.isAdmin || $store.getters.isOwner"
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">person</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <div class="justify-content-end">
                    <v-select :items="types"
                          :disabled="!is_permission_edit"
                          v-model="details.type_user"
                          @change="updatePermissions"
                    >
                    </v-select>
                  </div>
                </v-list-tile-content>
<!--                <v-list-tile-content>-->
<!--                  <v-switch-->
<!--                      v-model="details.is_superuser"-->
<!--                      @change="updatePermissions"-->
<!--                      :disabled="!is_permission_edit"-->
<!--                  ></v-switch>-->
<!--                </v-list-tile-content>-->
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">branding_watermark</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Cards prefix')}}</v-list-tile-title>
                  <p class="mb-0">{{details.rrr}}
                  </p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">check</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Status verification')}}</v-list-tile-title>
                  <p class="mb-0">{{details.is_activated}}
                  </p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
                  v-if="!$store.getters.isOwner || ($store.getters.isOwner && details.type_user !== 'O')"
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">account_balance_wallet</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Balance')}}</v-list-tile-title>
                  <p class="mb-0">{{details.balance + $store.getters.currency}}</p>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-if="isAdminOrOwnerAndNotSelf" v-slot:activator="{ on }">
                      <v-btn icon ripple v-on="on">
                        <v-icon color="grey lighten-1">edit</v-icon>
                      </v-btn>
                    </template>
                    <v-card style="min-height: 268px">
                      <v-card-text>
                        <v-container grid-list-md>
                          <v-layout wrap>
                            <v-flex xs12>
                              <v-form
                                  ref="form">
                                <v-text-field
																	:label="t('Balance')"
																	type="number" required
																	:disabled="true"
																	v-model="details.balance">
                                </v-text-field>
                                <v-text-field
																	:label="t('Add money')"
																	type="number" required
																	:rules="[rules.counter,rules.required]"
																	v-model="addBalance"
                                >
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                      <v-card-actions
												v-if="!waitingBalanceUpdate"
											>
                        <v-spacer>
                        </v-spacer>
													<v-btn color="primary" text @click="closeDialog">Close</v-btn>
													<v-btn color="primary" text @click="changeBalance">Save</v-btn>
                      </v-card-actions>
											<v-flex
												v-else
												class="text-xs-center"
											>
												<v-progress-circular
													indeterminate
													color="primary"
												></v-progress-circular>
											</v-flex>
                    </v-card>
                  </v-dialog>
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile
                  v-if="$store.getters.isOwner && details.type_user !== 'O'"
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">account_balance_wallet</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Credit')}}</v-list-tile-title>
                  <p class="mb-0">{{details.credit + $store.getters.currency}}</p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">card_membership</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Card price')}}</v-list-tile-title>
                  <p class="mb-0">{{details.price_card + $store.getters.currency}}</p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">mail</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Email')}}</v-list-tile-title>
                  <p class="mb-0">{{details.email}}</p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">phone</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Phone')}}</v-list-tile-title>
                  <p class="mb-0">{{details.telephone}}</p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">place</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Address')}}</v-list-tile-title>
                  <p class="mb-0">{{details.address}}</p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">place</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Zone')}}</v-list-tile-title>
                  <p class="mb-0">{{details.zone}}</p>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                  avatar
                  @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">comment</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{t('Comment')}}</v-list-tile-title>
                  <p class="mb-0">{{details.comment}}
                  </p>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-card>
					<cards-and-available-cards 
						v-if="$vuetify.breakpoint.xs"
						ref="cards_and_available_cards"
					></cards-and-available-cards>
					<logs :logs="details.logs"/>
        </v-flex>
				<cards-and-available-cards 
					v-if="!$vuetify.breakpoint.xs"
					ref="cards_and_available_cards"
				></cards-and-available-cards>
		</v-layout>
      <v-btn
				color="primary"
				dark
				fixed
				bottom
				right
				fab
				transition="slide-y-transition"
				v-if="($store.getters.isAdmin || $store.getters.isOwner) " 
      >
			<!-- //&& availableCards --->
        <v-icon @click.stop="addManyCards = true">library_add</v-icon>
      </v-btn>
    </v-container>
  </v-content>
</template>

<script>
  import axios from 'axios';
	import CardsAndAvailableCards from '../../components/Cards/CardsAndAvailableCards.vue'
	import Logs from '../../components/Logs/Logs.vue'

  export default {
    name: "ResellersDetails",
		components: {CardsAndAvailableCards, Logs},
    data() {
      return {
				waitingBalanceUpdate: false,
        snack: false,
        snackColor: '',
        snackText: '',
        loading: true,
        snackbar: false,
        dialog: false,
        deleteDialog: false,
        searchLogs:'',
        text: 'Oops... Something went wrong',
        timeout: 5000,
        newBalance: 0,
        addBalance: 0,
        addManyCards: false,
        is_permission_edit: false,
        types: [],
        details: {
          pk: 0,
          address: "",
          balance: 0,
          cards: Array(0),
          email: '',
          is_permission_edit: false,
          type_user: '',
					logs: [],
        },
        min: 1,
        range: [1, 5],
        rules: {
          counter: value => value <= 2147483647 || this.$translate.locale['Max 2147483647'],
          number: value => {
            const pattern = /^\d+$/;
            return pattern.test(value) || this.$translate.locale['Invalid number.']
          },
          counterRange: value => (value <= 2147483647 && value >= 1) || this.$translate.locale['Min 1 and Max 2147483647'],
          numberRange: value => {
            const pattern = /^\d+$/;
            return pattern.test(value) || this.$translate.locale['Invalid number.']
          },
					required: value => (!!value || value === 0) || this.$translate.locale['Required.'],
        },
      }
    },
    computed: {
      max() {
        return  99999;
      },
      canDelete() {
        const viewingAdmin = this.is_admin;
        const viewingOwner = this.details.type_user === 'O';
        const loggedAsAdmin = this.$store.getters.isAdmin;
        const loggedAsOwner = this.$store.getters.isOwner;

        const notViewingAdminAsAdmin = !(loggedAsAdmin && (viewingAdmin || viewingOwner));
        const notViewingOwnersAsOwners = !(loggedAsOwner && viewingOwner);
        const isAdminOrOwner = loggedAsAdmin || loggedAsOwner;

        return isAdminOrOwner && notViewingAdminAsAdmin && notViewingOwnersAsOwners;
      },
      isSelf() {
        return this.$store.getters.getPk === parseInt(this.$route.params.id)
      },
      is_admin() {
        return this.details.type_user === 'A'
      },
      isAdminOrOwnerAndNotSelf() {
        return (this.$store.getters.isOwner || this.$store.getters.isAdmin) && !this.isSelf
      }
    },
    methods: {
      initTypes() {
        this.types = [
          {value: 'A', text: 'Administrator'},
          {value: 'M', text: 'Manager'},
          {value: 'R', text: 'Reseller'},
        ];
        if (this.$store.getters.isOwner && (this.$store.getters.getPk === parseInt(this.$route.params.id))) {
          this.types.unshift({value: 'O', text: 'Owner'});
        }
      },
      getData() {
        axios.get(`/api/resellers/${this.$route.params.id}/`)
          .then((response) => {
            if (response.status === 200) {
              this.details = response.data;
              if(localStorage.currentLanguage === 'arabian'){
                this.details.logs = JSON.parse(
                  JSON.stringify(this.details.logs)
                    .replace(/(ID)/g,'هوية شخصية')
                    .replace(/(CARD)/g,'بطاقة')
                    .replace(/(LOG)/g,'سجل')
                    .replace(/(SUBSCRIBER)/g,'مكتتب')
                    .replace(/(card)/g,'بطاقة')
                    .replace(/(Card)/g,'بطاقة')
                    .replace(/(to subscriber)/g,'إلى المشترك')
                    .replace(/(Get)/g,'احصل على')
                    .replace(/(Remove)/g,'إزالة')
                    .replace(/(pick up the)/g,'التقط')
                    .replace(/(Pick up)/g,'امسك')
                    .replace(/(to reseller)/g,'للموزع')
                    .replace(/(Tie)/g,'ربطة عنق')
                    .replace(/(Payed)/g,'سيولي')
                    .replace(/(Price)/g,'السعر')
                    .replace(/(handed subscriber)/g,'سلم مشترك')
                    .replace(/(the card)/g,'بطاقة')
                    .replace(/(Balance)/g,'توازن')
                    .replace(/(Update expired date)/g,'تحديث تاريخ انتهاء الصلاحية')
                    .replace(/(Reseller)/g,'موزع')
                    .replace(/(RESELLER)/g,'موزع')
                    .replace(/(package)/g,'صفقة')
                    .replace(/(add)/g,'إضافة')
                    .replace(/(Add)/g,'إضافة')
                    .replace(/(enable)/g,'مكن')
                    .replace(/(disable)/g,'تعطيل')
                    .replace(/(suspend subscription)/g,'تعليق الاشتراك')
                    .replace(/(remove)/g,'إزالة')
                    .replace(/(Remove)/g,'إزالة')
                    .replace(/(from subscriber )/g,'من المشترك')
                    .replace(/(from reseller)/g,'من الموزع')
                    .replace(/(Created)/g,'خلقت')
                    .replace(/(Edit balance)/g,'تحرير الرصيد')
                    .replace(/(Deleted)/g,'تم الحذف')
                    .replace(/(Pick up card from reseller)/g,'التقط بطاقة من الموزع')
                    .replace(/(Card add to subscriber)/g,'إضافة بطاقة للمشترك')
                    .replace(/(New value)/g,'قيمة جديدة')
                    .replace(/(Remove card)/g,'أزل البطاقة')
                    .replace(/(Registered)/g,'مسجل')
                    .replace(/(Created card)/g,'بطاقة تم إنشاؤها')
                );
              }
              this.newBalance = this.details.balance;
              this.details.is_activated = this.details.is_activated ? 'Active' : 'Inactive';
              axios.get(`/api/is-permission-editable/${this.$route.params.id}/`)
                .then((response) => {
                  if (response.status === 200) {
                    this.is_permission_edit = response.data.permission;
                  }
                }).catch((error) => {
                this.text = "Connection error";
                this.snackbar = true;
              });
            }
          }).catch((error) => {
            this.text = "Connection error";
            this.snackbar = true;
        });
      },
      changeBalance() {
        if (!this.$refs.form.validate()) {
          this.text = "Fill the form correctly";
          this.snackbar = true;
        } else {
          this.loading = true;
          const putBody = {
            balance: this.details.balance.toString().replace(/,/g, '.'),
            addBalance: this.addBalance
          };
					this.waitingBalanceUpdate = true;
          axios.put(`/api/resellers/edit/balance/${this.$route.params.id}/`, putBody)
            .then((response) => {
              if (response.status === 200) {
                this.newBalance = this.details.balance;
                this.loading = false;
                this.dialog = false;
                this.text = "Balance changed!";
                this.snackbar = true;
                this.addBalance = 0;
                this.$store.dispatch('setBalance', response.data.balance);
              }
            }).catch((error) => {
              this.text = error.response.data.details || "Error while changing balance";
              this.snackbar = true;
              this.dialog = false;
              this.details.balance = this.newBalance;
						}).finally(() => {
							this.waitingBalanceUpdate = false;
						});
        }
      },
      closeDialog() {
        this.dialog = false;
        this.addBalance = 0;
        this.details.balance = this.newBalance;
      },
      applyManyCards() {
        if (!this.$refs.formManyCards.validate()) {
          this.text = "Fill the form correctly";
          this.snackbar = true;
        } else {
          this.loading = true;
          axios.put(`/api/resellers/tie/cards/${this.$route.params.id}/`, {start: this.range[0], stop: this.range[1]})
            .then((response) => {
              if (response.status === 200) {
								this.$refs.cards_and_available_cards.updateCards();
                this.text = `Cards were applied!`;
                this.snackbar = true;
                this.addManyCards = false;
              }
            }).catch((error) => {
            if (error.response.status === 400) {
              this.text = "Incorrect range";
              this.snackbar = true;
              this.dialog = false;
            }else{
              this.text = "Connection error";
              this.snackbar = true;
            }
          });
        }
      },
      deleteSubscriber() {
        axios.delete(`/api/resellers/delete/${this.$route.params.id}/`)
          .then((response) => {
            if (response.status === 204) {
              this.$router.push('/resellers')
            }
          }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });
      },
      updatePermissions(typeUser){
        axios.put(`/api/change-permission/${this.$route.params.id}/`,{type_user: typeUser})
          .then((response) => {
            if (response.status === 200) {
              this.text = "Permissions changed!";
              this.snackbar = true;
              this.details.type_user = response.data.type_user;
            }
          }).catch((error) => {
            this.text = "Connection error";
            this.snackbar = true;
        });
      }
    },
    watch: {
      'addBalance': {
        handler(newValue) {
          if (newValue === '') {
            this.details.balance = this.newBalance;
          }
          let b = parseFloat(this.newBalance);
          if (newValue) {
            this.details.balance = b + parseFloat(newValue);
          }
        }
      },
    },
    beforeCreate() {
      if (!this.$store.getters.loggedIn) {
        this.$router.push('/')
      }
      if(!this.$store.getters.isAdmin && !this.$store.getters.isOwner){
        this.$router.push('/cards')
      }
    },
    mounted() {
      this.getData();
      this.initTypes();
    }
  }
</script>

<style scoped>
  /*.v-list__tile__sub-title {*/
  /*overflow-x: auto;*/
  /*}*/
</style>
